
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddBalanceModal from "@/components/modals/forms/AddBalanceModal.vue"
import AddPointModal from "@/components/modals/forms/AddPointModal.vue"

export default defineComponent({
  components: {
    AddBalanceModal,
    AddPointModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("用户列表", ["用户管理;members"])
    })

    const store = useStore()
    const router = useRouter()
    const tableData = ref([])
    const currentMemberId = ref(0)

    const setCurrentMemberId = (id) => {
      currentMemberId.value = id
    }

    const formData = ref({
      nickname: "",
      mobile: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    const query = () => {
      store
        .dispatch(Actions.GET_MEMBERS, { ...formData.value, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentMembers;
          page.value.totalResult = store.getters.currentMemberCount;
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        nickname: "",
        mobile: "",
      };
    }

    const add_balance = (member_id, balance) => {
      if (balance <= 0) {
        return
      }

      store
        .dispatch(Actions.ADD_MEMBER_BALANCE, {
          id: member_id,
          value: {
            balance: balance
          }
        })
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }
    const add_point = (member_id, point) => {
      if (point <= 0) {
        return
      }

      store
        .dispatch(Actions.ADD_MEMBER_POINT, {
          id: member_id,
          value: {
            point: point
          }
        })
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    const onDownload = () => {
      store
        .dispatch(Actions.GET_DOWNLOAD_MEMBERS)
        .then(() => {
          const blob = new Blob([store.getters.currentDownloadMembers], { type: "application/xlsx" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `members_${formatDate(Date().toString())}.xlsx`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      setCurrentMemberId,
      add_balance,
      add_point,
      currentMemberId,
      onDownload
    }
  },
})
